document.addEventListener("turbo:load", () => {

  const okThanks = document.querySelector(".euro-ok-thanks-button");

  if (okThanks) {
    const csrfToken = document.head.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const url = '/update_has_reward';
    const hasRewardAllContainer = document.querySelector(".user-has-reward-all-container");
    const hasRewardBlackBg = document.querySelector(".euro-free-coin-background-blacked-out");

    okThanks.addEventListener("click", (e) => {
      updateUserHasReward(url, csrfToken, { has_reward: false })
      hasRewardAllContainer.style.bottom = "-300vw";
      hasRewardBlackBg.style.display = "none";
    })
  }


  function updateUserHasReward(url, csrfToken, params) {
    fetch(url, {
      method: 'PATCH',
      headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify(params)
    })
    .then(response => {
        if (response.ok) {
          console.log("ok");
        } else {
        throw new Error('Error: ' + response.status);
        }
    });
  }
})
