document.addEventListener("turbo:load", () => {
  const freeCoinButton = document.querySelector(".euro-top-part-freecoins-container");
  const freeCoinContainer = document.querySelector(".euro-free-bet-explanation-container");
  const freeCoinBackground = document.querySelector(".euro-free-coin-background");
  const freeCoinCloseButton = document.querySelector(".euro-ok-close");

  if (freeCoinButton) {
    freeCoinOperations(freeCoinButton, freeCoinContainer, freeCoinBackground, freeCoinCloseButton)
  }

  function freeCoinOperations(freeCoinButton, freeCoinContainer, freeCoinBackground, freeCoinCloseButton) {
    freeCoinButton.addEventListener("click", (e) => {
      freeCoinBackground.style.display = "block";
      freeCoinContainer.style.bottom = "0"
    })

    freeCoinCloseButton.addEventListener("click", (e) => {
      freeCoinBackground.style.display = "none";
      freeCoinContainer.style.bottom = "-300vw"
    })


    freeCoinBackground.addEventListener("click", (e) => {
      freeCoinBackground.style.display = "none";
      freeCoinContainer.style.bottom = "-300vw"
    })
  }
})
